window.io = require('socket.io-client');
window.$ = window.jQuery = require('jquery');
const QR = require('qrcode');
const Hls = require('hls.js');
const uuid = require('uuid');

function draw(txt) {
    QR.toCanvas(document.getElementById('qrcode'), txt, {
        color: {
            dark: '#000',
            light: '#00000000'
        },
        errorCorrectionLevel: 'L',
        height: 180,
        width: 180,
        background: '000',
        margin: 1,
    });
}

$(document).ready(function () {
    let socket = io.connect('https://matchpromotion.net4media.net:2063', {
        withCredentials: false,
    });
    var oldurl = "";
    const hlsconfig = {
        liveSyncDurationCount: 2,
        liveMaxLatencyDurationCount: Infinity,
        progressive: false,
        lowLatencyMode: true,
        enableWorker: true,
        enableSoftwareAES: false,
        maxBufferHole: 1,
        maxFragLookUpTolerance: 0.5,
        latency: 1,
    };
    const video = document.getElementById('player');
    $('#sldpplayer').hide();
    var sldpPlayer = SLDP.init({
        container:           'sldpplayer',
        stream_url:          '',
        buffering:           500,
        muted:               true,
        autoplay:            true,
        height:              1080,
        width:               1920
    });
    sldpPlayer.destroy();
    let videoSrc = 'https://st3.net4media.net:8082/FTV-HLS/8Jr4545cdfd5534h887TT566ssd61rtv/playlist.m3u8';
    oldurl = videoSrc;
    var hls = new Hls(hlsconfig);
    hls.loadSource(videoSrc);
    hls.attachMedia(video);
    hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        video.muted = true;
        video.play();
    });

    socket.on('server-send-unmute', () => {
        video.muted = false;
    });

    socket.on('server-send-change-source', function (data) {
        if(oldurl != data.url) {
            try {
                sldpPlayer.destroy();
            } catch (e) {
                //
            }
            try {
                hls.destroy();
            } catch (e) {
                //
            }
            if(data.url.includes('wss://')) {
                $('#player').hide();
                $('#sldpplayer').show();
                sldpPlayer = SLDP.init({
                    container:           'sldpplayer',
                    stream_url:          data.url,
                    muted:               true,
                    autoplay:            true,
                    buffering:           500,
                    height:              1080,
                    width:               1920,
                });
                setTimeout(function () {
                    sldpPlayer.refreshVUMeterUI();
                },500)
            }
            else {
                $('#player').show();
                $('#sldpplayer').hide();
                hls = new Hls(hlsconfig);
                hls.loadSource(data.url);
                hls.attachMedia(video);
                hls.on(Hls.Events.MEDIA_ATTACHED, function () {
                    video.muted = true;
                    video.play();
                });
            }
        }
    });

    draw('https://videoqr.n4m.ro/control/index.html#' + uuid.v4());

    socket.on('server-send-change-qr', (data) => {
        draw(data.msg);
    });

    setInterval(function () {
        ToggleQR();
    }, 15000);

});

function ToggleQR() {
    let qr = $('#qrcode');
    if(qr.is(":visible")) {
        qr.slideToggle(800);
    }
    else {
        draw('https://videoqr.n4m.ro/control/index.html#' + uuid.v4());
        qr.slideToggle(800);
    }
}